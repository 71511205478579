<template>
  <div class="P-sign-up-box">
    <div class="sign-up-header">
      <h5 class="title">Sign up</h5>
      <p class="sub-text">Personal information</p>
    </div>
    <div class="sign-up-input-blocks">
      <div class="P-input-box">
        <OttInput
          v-model="form.firstname"
          :error="$v.form.firstname.$error"
          label="First Name"
        />
      </div>
      <div class="P-input-box">
        <OttInput
          v-model="form.lastname"
          :error="$v.form.lastname.$error"
          label="Last Name"
        />
      </div>
      <div class="P-input-box">
        <OttInput
          v-model="form.email"
          :error="validationEmail || $v.form.email.$error"
          label="Email Address"
        />
      </div>
      <div class="P-input-box">
        <PasswordStrength
          :input-value="form.password"
          :error="$v.form.password.$error"
          @emitFunc="validationFunc('password', $event)"
        />
      </div>
      <p class="neutral--text P-password-text">
        The password must contain at least 8 characters, 1 letter and 1 number
      </p>
      <ErrorMessageContainer
        v-if="validationEmail"
        message="The user with the current E-mail is already created."
      />
      <div class="P-button">
        <OttButton
          :click="submitPersonalInfo"
          class="primary-lg-btn"
          icon-after
          text="Next"
        />
      </div>
      <div class="sign-up-footer">
        <router-link to="/auth/sign-in" class="link-txt"
          >Sign in for an account</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import OttInput from "@/components/vuetifyComponents/OttInput";
import PasswordStrength from "@/components/PasswordStrength";
import OttButton from "@/components/vuetifyComponents/OttButton";
import allCountry from "@/constants/all-countries";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import {
  required,
  email,
  minLength,
  helpers
} from "vuelidate/lib/validators";
const letterAndNumber = helpers.regex(
  "letterAndNumber",
  /(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/
);
export default {
  components: { ErrorMessageContainer, OttButton, PasswordStrength, OttInput },
  validations: {
    form: {
      firstname: { required },
      lastname: { required },
      email: { required, email },
      password: { required, minLength: minLength(8), letterAndNumber }
    }
  },
  data() {
    return {
      form: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        phone: ""
      },
      validationEmail: false
    };
  },
  methods: {
    ...mapActions({
      validateEmailUser: "registration/validateEmailUser"
    }),

    ...mapMutations({
      changeTabRegistration: "registration/changeTabRegistration",
      fetchPersonalInfo: "registration/fetchPersonalInfo",
      
    }),

    async submitPersonalInfo() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        const body = {
          email:this.form.email
        }
        await this.validateEmailUser(body)
          .then(data => {
            allCountry.forEach(item => {
              if (data?.geoIpInfo?.countryCode !== undefined) {
                if (item.iso2 === data.geoIpInfo.countryCode.toUpperCase()) {
                  this.form.phone = "+" + item.dialCode;
                }
              }
            });
            if (!data.result) {
              this.validationEmail = false;
              this.changeTabRegistration(2);
              this.fetchPersonalInfo(this.form);
            } else {
              this.validationEmail = true;
            }
          });
      }
    },
    validationFunc() {
      this.validationEmail = false;
      const [fieldName, value] = arguments;
      this.form[fieldName] = value;
      this.$v.form[fieldName].$touch();
    }
  }
};
</script>
<style scoped lang="scss">
@import "./src/assets/scss/authGeneral";
.sub-text {
  text-align: center;
  text-transform: capitalize;
}
.title {
  text-align: center;
  text-transform: capitalize;
}
.P-button {
  margin-top: 15px;
}
.sign-up-footer {
  padding-top: 7px;
  margin-top: 20px;
  width: 100%;
}
.link-txt {
  font-size: 14px;
  font-weight: $semiBold;
  color: $secondary-color;
  transition: $transition;
  &:hover {
    color: $primary-color !important;
  }
}
.P-password-text {
  text-align: center;
  font-size: 12px;
}
</style>
