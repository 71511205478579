<template>
  <div class="auth-cont">
    <div>
      <auth-header></auth-header>
      <div class="auth-body">
        <div
          class="P-sign-up-block d-flex"
          :class="{ 'P-animation': activeTab === 2 }"
        >
          <div class="P-animation-box">
            <SignUpPersonalInfo v-show="activeTab === 1" />
          </div>
          <div class="P-animation-box">
            <SignUpCompanyInfo v-show="activeTab === 2" />
          </div>
        </div>
      </div>
    </div>
    <auth-footer></auth-footer>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AuthFooter from "./components/authFooter";
import AuthHeader from "./components/authHeader";
import SignUpPersonalInfo from "./components/SignUpPersonalInfo";
import SignUpCompanyInfo from "./components/SignUpCompanyInfo";
export default {
  components: { SignUpCompanyInfo, AuthHeader, AuthFooter, SignUpPersonalInfo },
  computed: {
    ...mapState({
      activeTab: state => state.registration.activeTab
    })
  }
};
</script>
<style lang="scss" scoped>
@import "./src/assets/scss/authGeneral";
@import "./src/assets/scss/variables";
.P-sign-up-block {
  width: 720px;
  position: relative;
  overflow: hidden;
  transition: $transition;
  &.P-animation {
    transform: translateX(-360px);
  }
}
.P-animation-box {
  width: 360px;
  min-width: 360px;
}
.auth-body {
  align-items: normal;
  overflow: hidden;
}
</style>
