<template>
  <div class="P-sign-up-box">
    <div class="sign-up-header">
      <span class="mdi mdi-arrow-left arrow-back" @click="goBack"></span>
      <h5 class="title">Sign up</h5>
      <p class="sub-text">Company information</p>
    </div>
    <div class="sign-up-input-blocks">
      <div class="P-input-box">
        <OttInput
          v-model="form.companyName"
          :error="$v.form.companyName.$error"
          label="Company name"
        />
      </div>
      <div class="P-input-box">
        <OttInput
          v-model="form.website"
          :error="$v.form.website.$error"
          label="Website"
        />
      </div>
      <div class="P-input-box">
        <OttInput
          v-model="form.companyEmail"
          @emitFunc="updateValidation"
          :error="validationEmail || $v.form.companyEmail.$error"
          label="Email Address"
        />
      </div>
      <div class="P-input-box">
        <ott-phone-number
          v-model="form.phone"
          :error="(validPhoneNumber || $v.form.phone.$error) && showPhoneError"
          @onInput="validationPhoneNumber"
          @onChange="showPhoneError = true"
        />
      </div>
      <h3 class="secondary--text amount-title">Amount of current clients</h3>
      <div class="amount-client-list d-flex flex-wrap">
        <div
          v-for="(list, index) of mountList"
          :key="index"
          class="padding-box"
        >
          <span
            class="amount-box secondary--text"
            :class="{
              'active-amount': form.clientAmount === index,
            }"
            @click="selectAmount(index)"
          >
            {{ list.value }}
          </span>
        </div>
      </div>
      <div class="P-input-box">
        <OttInput
          v-model="form.channelAmount"
          @emitFunc="validationNumber"
          :min="0"
          :error="$v.form.channelAmount.$error || channelsValidation"
          type="number"
          label="Amount of TV channels"
        />
      </div>
      <div class="P-input-box">
        <OttTextarea
          v-model="form.description"
          :rows="2"
          :error="$v.form.description.$error"
          label="Briefly describe your project"
        />
      </div>
      <error-message-container v-if="isShowMessage" :message="message" />

      <div class="P-button">
        <OttButtonLoader
          @onClick="submitCompanyInfo"
          :is-loading="isLoading"
          button-text="Sign up"
          className="primary-lg-btn"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { email, required, helpers } from "vuelidate/lib/validators";
import OttInput from "@/components/vuetifyComponents/OttInput";
import PasswordStrength from "@/components/PasswordStrength";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";

const website = helpers.regex("website", /^(http|https):\/\//);
export default {
  components: {
    ErrorMessageContainer,
    OttButtonLoader,
    OttTextarea,
    OttPhoneNumber,
    OttButton,
    PasswordStrength,
    OttInput,
  },
  data() {
    return {
      mountList: [
        { value: "0 - Startup" },
        { value: "1 - 10K" },
        { value: "10K - 20K" },
        { value: "20K - 50K" },
        { value: "50K - 100K" },
        { value: "100K+" },
      ],
      form: {
        companyName: "",
        companyEmail: "",
        website: "",
        phone: "",
        clientAmount: 0,
        channelAmount: "",
        description: "",
      },
      validPhoneNumber: false,
      isShowMessage: false,
      message: "",
      validationEmail: false,
      channelsValidation: false,
      showPhoneError: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.appModule.isLoading,
      formData: (state) => state.registration.form,
    }),
  },
  watch: {
    formData(data) {
      this.form.phone = data.phone;
    },
  },
  validations: {
    form: {
      companyName: { required },
      companyEmail: { required, email },
      website: { required },
      phone: { required },
      clientAmount: { required },
      channelAmount: { required },
      description: { required },
    },
  },
  methods: {
    ...mapMutations({
      changeTabRegistration: "registration/changeTabRegistration",
      setLoad: "registration/setLoad",
      fetchCompanyInfo: "registration/fetchCompanyInfo",
      setCountryCode: "registration/setCountryCode",
    }),

    ...mapActions({
      validateEmailCompany: "registration/validateEmailCompany",
      validatePhoneCompany: "registration/validatePhoneCompany",
      registration: "registration/registration"
    }),

    selectAmount(index) {
      this.form.clientAmount = index;
    },
    updateValidation() {
      this.validationEmail = false;
    },
    goBack() {
      this.changeTabRegistration(1);
    },
    validationFunc() {
      const [fieldName, value] = arguments;
      this.form[fieldName] = value;
      this.$v.form[fieldName].$touch();
    },
    validationNumber() {
      this.channelsValidation =
        +this.form.channelAmount < 0 || !this.form.channelAmount;
    },
    async submitCompanyInfo() {
      this.$v.form.$touch();
      this.showPhoneError = true;
      if (
        !this.$v.form.$error &&
        !this.validPhoneNumber &&
        !this.channelsValidation
      ) {
        this.setLoad(true);
        this.fetchCompanyInfo(this.form);
        await Promise.all([
          this.checkEmailValidation(),
          this.checkPhoneValidation(),
        ]).then(async (data) => {
          if (data[0] && data[1]) {
            await this.registrationProvider();
          }
        });
      }
    },
    validationPhoneNumber() {
      const [phone, obj] = arguments;
      this.validPhoneNumber = !obj.isValid;
      this.$v.form["phone"].$touch();
      this.setCountryCode(obj.regionCode);
    },
    checkEmailValidation() {
      const body = {
        email: this.form.companyEmail,
      };
      return this.validateEmailCompany(body)
        .then((dataCheck) => {
          if (!dataCheck) {
            this.setLoad(false);
            return Promise.resolve(true);
          } else {
            this.isShowMessage = true;
            this.validationEmail = true;
            this.message =
              "The user with the current E-mail is already created.";
            return Promise.resolve(false);
          }
        });
    },
    checkPhoneValidation() {
      const body = {
        phone: this.form.phone,
      };
      return this.validatePhoneCompany(body)
        .then((dataCheck) => {
          if (!dataCheck) {
            this.setLoad(false);
            return Promise.resolve(true);
          } else {
            this.isShowMessage = true;
            this.validPhoneNumber = true;
            this.message =
              "The user with the current Phone Number is already created.";
            return Promise.resolve(false);
          }
        });
    },
    async registrationProvider() {
      await this.registration().then(
        (data) => {
          this.$router.push({ path: "/auth/register-pending" });
        },
        (error) => {
          this.setLoad(false);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./src/assets/scss/authGeneral";
@import "src/assets/scss/variables";

.sub-text {
  text-align: center;
  text-transform: capitalize;
}

.title {
  text-align: center;
  text-transform: capitalize;
}

.amount-box {
  border-radius: 17px;
  padding: 7px;
  text-align: center;
  display: block;
  box-shadow: $box-shadow;
  font-size: $txt14;
  cursor: pointer;
  transition: 0.3s;

  &:hover,
  &.active-amount {
    background-color: $primary-color !important;
    color: $white !important;
  }
}

.padding-box {
  width: 33.33%;
  padding: 10px 15px;
}

.amount-client-list {
  margin: 0 -15px 25px;
}

.amount-title {
  margin-bottom: 10px;
  font-size: $txt14;
}

.arrow-back {
  font-size: 25px;
  position: absolute;
  cursor: pointer;
  color: $neutral-color;
}

.sign-up-header {
  position: relative;
}
</style>
