<template>
  <v-textarea
    class="textarea"
    outlined
    :label="label"
    :dense="dense"
    :value="inputValue"
    :disabled="disabled"
    :rows="rows"
    :error="error"
    :auto-grow="isAutoGrow"
    @input="inputEmit"
    @keydown.enter="onEnterKeyDown"
    ref="textarea"
  >
    <template v-if="isAppendIcon" v-slot:append>
      <v-icon @click="$emit('appendIcon')">mdi-emoticon-happy-outline</v-icon>
    </template>
  </v-textarea>
</template>

<script>
export default {
  name: "OttTextarea",
  props: {
    label: { type: String },
    rows: {
      type: Number,
      default: null
    },
    timeoutInput: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    dense: {
      type: Boolean,
      default: true
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isAppendIcon: {
      type: Boolean,
      default: false
    },
    isAutoGrow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      inputValue: "",
      timeOut: null
    };
  },
  watch: {
    value(data) {
      this.inputValue = data;

      if (this.timeoutInput) {
        this.fetchEntriesDebounced()
      }
    }
  },
  mounted() {
    this.inputValue = this.value;
  },
  methods: {
    fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this.timeOut)

      // delay new call 300ms
      this.timeOut = setTimeout(() => {
        this.$emit('timeoutInput', this.value)
      }, 300)
    },
    inputEmit(val) {
      this.$emit("input", val);
      this.$emit("emitFunc");
    },
    onEnterKeyDown(event) {
      if (event.shiftKey && event.key === "Enter") {
        this.$emit("shift-enter", event)
      } else {
        this.$emit("keydown", event)
      }
    },
    focusTextareaWithLength(length) {
      const textareaElement = this.$refs.textarea.$el.querySelector('textarea')
      textareaElement.focus()
      textareaElement.setSelectionRange(length, length)
    },
    focusTextarea() {
      this.$refs.textarea.focus()
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
.textarea::v-deep {
  textarea {
    resize: none;
    color: $secondary-color !important;
    font-size: $txt14;
  }

  &.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
    min-height: 30px !important;
  }

  &.v-text-field.v-text-field--enclosed .v-text-field__details,
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
    padding: 0 18px !important;
  }

  &.v-text-field--outlined .v-input__slot fieldset {
    padding-left: 14px !important;
  }

   .v-label {
    font-size: $txt14 !important;
    font-weight: $regular !important;
    color: $neutral-color;
    text-transform: capitalize;
  }

  &.v-input--dense .v-label {
    top: 8px !important;
    left: 7px !important;
  }

  &.v-text-field input {
    line-height: 24px !important;
    font-size: $txt14;
  }

  &.v-input--has-state.error--text .v-label {
    -webkit-animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.v-text-field--outlined.v-input--has-state fieldset,
  .v-text-field--outlined.v-input--is-focused fieldset {
    border: 1.5px solid !important;
  }
}

.theme--light::v-deep {
  &.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    color: $neutral-color;
  }

  .v-label {
    color: $neutral-color;
    text-transform: capitalize !important;
  }

  .v-text-field__slot input {
    color: $secondary-color !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    color: $neutral-color;
  }
}
.v-input__append-inner {
  margin-left: 10px !important;
}

::v-deep .v-input__append-inner {
  margin-left: 10px !important;
}

.theme--dark::v-deep {
  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    color: $primary-colorDM !important;
  }

  .v-label {
    color:$dark-gray !important;
    text-transform: capitalize !important;
  }

  .v-text-field__slot textarea {
    color: $white !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    color:$dark-gray !important;
  }
}
</style>
